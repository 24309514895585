@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

body {
  background-color: #000300;
}

html {
  font-family: 'Lato', sans-serif;
}